
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Indicadores",
    "icon": "pi pi-chart-line text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/ftp_logs",
    "label": "FTP Logs",
    "icon": "pi pi-server text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/clients",
    "label": "Clientes",
    "icon": "pi pi-briefcase text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/devices",
    "label": "Dispositivos",
    "icon": "pi pi-stopwatch text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/users",
    "label": "Usuarios",
    "icon": "pi pi-user text-primary",
    "iconcolor": "",
    "target": "",
    
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}